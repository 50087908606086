export default {
  public: [
    {
      path: "photo",
      name: "photo",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/photo.vue"),
    },
  ],
  member: [
    {
      path: "provider/register",
      name: "provider-register",
      meta: {
        formMode: "update", // 為了執行readApi
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/providerRegister.vue"),
    },
    // {
    //   path: 'provider/verify',
    //   name: 'provider-verify',
    //   component: () => import('@/modules/provider/views/providerVerify.vue'),
    // },
    // 'picker', 'dispatcher', 'freightman'
    // 供應商資料/基本資料
    {
      path: "provider/info",
      name: "provider-info",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/providerInfo.vue"),
    },
    {
      path: "provider/provider-line",
      name: "provider-line",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/providerLine.vue"),
    },
    //
    {
      path: "provider/join/:provider_id",
      name: "provider-join",
      meta: {
        formMode: "update",
        allowedRoles: ["picker", "dispatcher", "freightman"],
      },
      component: () => import("@/modules/provider/views/providerJoin.vue"),
    },
    // 訂單列表
    {
      path: "provider/orders",
      name: "provider-orders",
      meta: {
        allowedRoles: ["picker"],
      },
      component: () => import("@/modules/provider/views/orderList.vue"),
    },
    // 訂單明細
    {
      path: "provider/order/:orderId/:providerId",
      name: "order-detail",
      meta: {
        allowedRoles: [],
      },
      component: () => import("modules/provider/views/orderDetail/index.vue"),
    },
    // 訂單派遣
    {
      path: "provider/deliveries",
      name: "provider-deliveries",
      meta: {
        allowedRoles: ["dispatcher"],
      },
      component: () => import("@/modules/provider/views/deliveryList.vue"),
    },
    // 貨運列表
    {
      path: "provider/delivery-mans",
      name: "provider-delivery-mans",
      meta: {
        allowedRoles: ["freightman"],
      },
      component: () => import("@/modules/provider/views/deliveryManList.vue"),
    },
    // 揀貨單/總表下載
    {
      path: "provider/pickingList/order/download",
      name: "provider-pickingList-order-download",
      meta: { public: true, allowedRoles: ["picker"] },
      component: () =>
        import(
          "@/modules/provider/views/pickingList/pickingListOrderDownload.vue"
        ),
    },
    // excel下載
    {
      path: "download/excel",
      name: "download-excel",
      meta: { public: true, allowedRoles: [] },
      component: () =>
        import(
          "@/modules/base/views/download/excel.vue"
        ),
    },
    // 退貨列表
    {
      path: "provider/backOrders",
      name: "provider-backOrders",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("modules/provider/views/backDetail/backOrderList.vue"),
    },
    // 退貨單明細
    {
      path: "provider/backOrders/:orderId",
      name: "provider-backOrder-detail",
      meta: {
        allowedRoles: [],
      },
      component: () => import("modules/provider/views/backDetail/index.vue"),
    },
    // 退款列表
    {
      path: "provider/refundList",
      name: "provider-refund-list",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("modules/provider/views/providerRefundList.vue"),
    },
    
    // 供應商 今日價格設定頁
    {
      path: "provider/today-price",
      name: "provider-today-price",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () => import("modules/provider/views/providerTodayPrice.vue"),
    },
    // 供應商 營業時間
    {
      path: "provider/business-hours",
      name: "provider-business-hours",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () => import("modules/provider/views/providerTimeEdit.vue"),
    },
    {
      path: "provider/line-message-edit",
      name: "provider-line-message-edit",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () =>
        import("modules/provider/views/providerLineMessageEdit.vue"),
    },
    // 邀請共同管理員
    {
      path: "provider/send-invite",
      name: "provider-send-invite",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () => import("modules/provider/views/providerSendInvite.vue"),
    },
    // 配送資訊
    {
      path: "provider/shipping-info",
      name: "provider-shipping-info",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () =>
        import("modules/provider/views/providerShippingInfo.vue"),
    },
    // 金流設定
    {
      path: "provider/payment-setting",
      name: "provider-payment-setting",
      meta: {
        formMode: "update",
        allowedRoles: [],
      },
      component: () =>
        import("modules/provider/views/providerPaymentSetting.vue"),
    },
    //偏好物流商
    {
      path: "provider/favorite-logistic",
      name: "provider-favorite-logistic",
      component: () => import("@/modules/provider/views/favoriteLogistic.vue"),
    },
    // 客戶管理列表總覽
    {
      path: "client/overview",
      name: "client-overview",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/views/clientOverview/clientOverview.vue"),
    },
    // 客戶管理列表
    {
      path: "client/list",
      name: "client-list",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/clientList.vue"),
    },
    // 客戶管理內頁
    {
      path: "client/:storeId/provider/:providerId",
      name: "client-info",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/clientInfo.vue"),
    },
    // 客戶分級設定列表
    {
      path: "vip/vip-list",
      name: "client-vipList",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/vip/vipList.vue"),
    },
    // 客戶分級設定內頁
    {
      path: "vip/vip-detail/:id",
      name: "client-vipDetail",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/vip/vipDetail.vue"),
    },
    // 選擇客戶分級
    {
      path: "client/vip-select",
      name: "client-vipSelect",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/vip/vipSelect.vue"),
    },
    // 接受合作
    {
      path: "client/status/accept",
      name: "client-status-accept",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/components/notifyPage/accept.vue"),
    },
    // 拒絕合作
    {
      path: "client/status/reject",
      name: "client-status-reject",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/components/notifyPage/reject.vue"),
    },
    // 取消合作
    {
      path: "client/status/cancel",
      name: "client-status-cancel",
      meta: {
        allowedRoles: ["master"],
      },
      component: () =>
        import("@/modules/provider/components/notifyPage/cancel.vue"),
    },
    // 請款總攬
    // {
    //   path: "apply-pay/overview",
    //   name: "apply-pay-overview",
    //   meta: {
    //     allowedRoles: [],
    //   },
    //   component: () =>
    //     import("@/modules/provider/views/applyPay/list/applyPayOverview.vue"),
    // },
    // 請款列表
    {
      path: "store/:storeId/apply-pay/list",
      name: "apply-pay-list",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/views/applyPay/list/applyPayList.vue"),
    },
    {
      path: "store/:storeId/apply-pay/:id/provider/:providerId",
      name: "apply-pay-detail",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/views/applyPay/detail/applyPayDetail.vue"),
    },

    // 報表列表
    {
      path: "report/list",
      name: "provider-report-list",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/report/list/index.vue"),
    },
    {
      path: "provider/operation-record",
      name: "operation-record",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/operationRecord.vue"),
    },
    {
      path: "identity/switch",
      name: "provider-switch-identity",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/views/providerSwitchIdentity.vue"),
    },
    // 綁定 Line Notify 頁面
    {
      path: "line-notify/relation",
      name: "line-notify-relation",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/provider/views/line/lineNotifyRelation.vue"),
    },
    // menu 獨立頁面
    {
      path: "menu",
      name: "menu",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/provider/views/menu.vue"),
    },
  ],
  FrontProvider: [
    {
      path: ":providerId",
      name: "front-provider-index",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/FrontProvider/views/IndexView.vue"),
    },
    {
      path: "product-lists/:providerId/:categoryId",
      name: "front-provider-product-lists",
      meta: {
        allowedRoles: [],
      },
      component: () =>
        import("@/modules/FrontProvider/views/ProductListView.vue"),
    },
    {
      path: "provider-login/:providerId",
      name: "front-provider-login",
      meta: {
        allowedRoles: [],
      },
      component: () => import("@/modules/FrontProvider/views/LoginView.vue"),
    },
  ],
};
